import * as React from 'react';

import type { ActionContext } from 'js/lib/ActionContext';

import { FluxibleContext } from './FluxibleContext';

type Props = {
  context: ActionContext;
};

export const FluxibleProvider: React.LegacyFunctionComponentWithChildren<Props> = ({ children, context }) => {
  return <FluxibleContext.Provider value={context}>{children}</FluxibleContext.Provider>;
};
