import * as Sentry from '@sentry/react';
import PQueue from 'p-queue';

import type DeviceProvider from './device.provider';
import { createDefaultDeviceProvider } from './device.provider';
import type { onEvents } from './emitter';
import Emitter from './emitter';
import Envelope from './envelope';
import type Events from './events';
import type SessionProvider from './session.provider';
import { createDefaultSessionProvider } from './session.provider';
import { createDefaultTransport } from './transport';
import type { Transport } from './transport';

/**
 * IMPORTANT. READ BEFORE CHANGING ANY LOGIC IN THE SEND EVENT FUNCTION.
 *
 * We accept promises as part of event data because some queries occurring
 * outside the client’s loop delay the sendEvent function significantly,
 * causing the envelope (contextual information) to be collected too late. In
 * many cases of redirection, this results in collecting information from the
 * subsequent page, leading to a duplicate page view.
 */

type Configuration = {
  batchUrl?: string;
  url?: string;
  version?: string;
};

type Middleware = <K extends keyof Events>(eventName: K, data: Events[K]) => Events[K] | Promise<Events[K]>;

type Options = Configuration &
  onEvents & {
    device?: DeviceProvider;
    queue?: PQueue;
    session?: SessionProvider;
    transport?: Transport;
    type?: 'web' | 'server';
  };

type Plugin = (client: Client) => void;

declare global {
  interface Window {
    appName?: string;
  }
}

const globalQueue = new PQueue({ concurrency: 1 });

/**
 * Client that sends events to the server.
 *
 * This class is responsible for managing the lifecycle of events, including
 * creating, sending, and handling errors. It uses device and session providers
 * to gather necessary context and a transport mechanism to send the events.
 */
class Client extends Emitter<onEvents> {
  /**
   * The device provider instance.
   */
  declare readonly device: DeviceProvider;

  /**
   * The queue for managing event sending.
   * @private
   */
  private declare readonly queue: PQueue;

  /**
   * The session provider instance.
   */
  declare readonly session: SessionProvider;

  /**
   * The transport mechanism for sending events.
   * @private
   */
  private declare readonly transport: Transport;

  /**
   * The type of the client, indicating the environment ('web' or 'server').
   */
  declare readonly type: 'web' | 'server';

  /**
   * The version of the client.
   */
  readonly version: string = '2.1.0';

  /**
   * Array of middleware functions to process events before sending.
   * @private
   */
  private middlewares: Middleware[] = [];

  /**
   * Constructor for the Client class.
   *
   * @param options - An object containing configuration options, event handlers, and providers.
   */
  constructor(options: Options = {}) {
    super();

    this.device = options.device ?? createDefaultDeviceProvider();
    this.queue = options.queue ?? globalQueue;
    this.session = options.session ?? createDefaultSessionProvider();
    this.transport =
      options.transport ??
      createDefaultTransport({
        batchUrl: options.batchUrl,
        url: options.url,
      });

    this.type = options.type ?? 'web';

    this.version = options.version ?? '2.1.0';
  }

  /**
   * Send an event to the server.
   *
   * @param eventName - The name of the event.
   * @param input - The data associated with the event.
   */
  async sendEvent<K extends keyof Events>(eventName: K, input: Events[K] | Promise<Events[K]>): Promise<void> {
    // Create a new session if the current one is expired
    if (this.session.get().isExpired()) {
      // Reset the session
      this.session.reset();

      // Session has started
      this.trigger('sessionStart', this.session.get());
    }

    // Store the updated session and device data
    this.session.touch();
    this.device.touch();

    // Wrap the data in an envelope for sending
    const envelope = new Envelope({
      app: window.appName ?? 'web',
      client: this,
      device: this.device.get(),
      key: eventName,
      session: this.session.get(),
    });

    this.trigger('beforeQueue', eventName);
    // We ignore the promise returned by the queue.add method because we don't want to wait for it.
    // Doing so, we create a deadlock.
    return this.queue.add(async () => {
      // We add to the queue this event. This function will wait for the information to be ready
      // and then send the event to the server. p-queue will handle them sequentially.
      try {
        // Process the event data through middleware
        const data = await this.middlewares.reduce<Promise<Events[K]>>(
          async (acc, middleware) => middleware(eventName, await acc),
          Promise.resolve(input)
        );

        // Update the envelope with the processed data
        envelope.value = data;

        // If one callback is defined as async, the event is sent before the callback is finished
        this.trigger('beforeSend', eventName, data);
        await this.transport.send(envelope);
        this.trigger('sent', eventName, data);
      } catch (e) {
        // We don't want to throw any error coming from eventing. We log them and continue.
        Sentry.captureException(e);
      }
    });
  }

  /**
   * Add a middleware function to process events before sending.
   *
   * @param callback - The middleware function.
   */
  middleware(callback: Middleware) {
    this.middlewares.push(callback);
  }

  /**
   * Register a plugin to extend the client's functionality.
   *
   * @param plugin - The plugin function.
   */
  register(plugin: Plugin) {
    plugin(this);
  }
}

export type { Plugin };

export default Client;
