import type React from 'react';

import { StylesProvider as MuiStylesProvider } from '@material-ui/core/styles';

import createGenerateClassName from '@common/theme/createGenerateClassName';
import createJss from '@common/theme/createJss';

type Props = {
  children?: React.ReactNode;
};

/**
 * This function is used to get the first comment node in the head element.
 * If the node is not found, it creates a new one and inserts it at the beginning of the head element.
 * This is used to ensure that the styles are injected at the beginning of the head element.
 */
export function getFirstCommentNode() {
  const nodeIterator = document.createNodeIterator(
    document.head,
    NodeFilter.SHOW_COMMENT
  );

  let firstCommentNode: Comment | null = null;

  let node = nodeIterator.nextNode() as Comment;
  while (node) {
    if (node.data === 'cds-jss-injection-point') {
      firstCommentNode = node;
      break;
    }
    node = nodeIterator.nextNode() as Comment;
  }

  if (!firstCommentNode) {
    const node = document.createComment('cds-jss-injection-point');
    document.head.insertBefore(node, document.head.firstChild);
    firstCommentNode = node;
  }

  return firstCommentNode;
}

const StylesProvider = ({ children }: Props) => {
  if (typeof window === 'undefined') {
    return <>{children}</>;
  }

  const firstCommentNode = getFirstCommentNode();

  const jss = createJss({
    insertionPoint: firstCommentNode,
  });

  const generateClassName = createGenerateClassName();

  return (
    <MuiStylesProvider generateClassName={generateClassName} jss={jss}>
      {children}
    </MuiStylesProvider>
  );
};

export default StylesProvider;
