import * as React from 'react';
import { Helmet } from 'react-helmet';

import URI from 'jsuri';

import { numberOfProducts } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { defaultImageHref } from 'bundles/seo/common/constants';

type Props = {
  url: string;
};

const MetatagsWrapper: React.LegacyFunctionComponentWithChildren<Props> = (props) => {
  const { url } = props;
  const uri = new URI(url);
  // Remove query params, fragment, trailing slash from the url
  const defaultCanonicalUrl = new URI()
    .setProtocol(uri.protocol())
    .setHost(uri.host())
    .setPath(uri.path())
    .toString()
    .replace(/\/$/, '');

  const defaultMeta = {
    title: 'Coursera | Online Courses From Top Universities. Join for Free',
    meta: [
      {
        name: 'description',
        content: `${numberOfProducts} courses from schools like Stanford and Yale - no application required. Build career skills in data science, computer science, business, and more.`,
      },
      {
        name: 'image',
        content: defaultImageHref,
      },

      {
        property: 'og:title',
        content: 'Coursera | Online Courses From Top Universities. Join for Free',
      },
      {
        property: 'og:description',
        content: `${numberOfProducts} courses from schools like Stanford and Yale - no application required. Build career skills in data science, computer science, business, and more.`,
      },
      { property: 'og:url', content: url },
      {
        property: 'og:image',
        content: defaultImageHref,
      },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: 'website' },
    ],
    link: [{ rel: 'canonical', href: defaultCanonicalUrl }],
  };

  return (
    <div className="rc-MetatagsWrapper">
      <Helmet {...defaultMeta} />
      {props.children}
    </div>
  );
};

export default MetatagsWrapper;
