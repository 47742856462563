// If you update this file, remember to update the server file accordingly
import { memoize } from 'lodash';

import type Store from './base';
import CookieStore from './cookie';
import LocalStorageStore from './localStorage';
import MemoryStore from './memory';

/**
 * Create a default store instance.
 *
 * This function memoizes the creation of a default store instance. It attempts to create
 * a `LocalStorageStore` and falls back to a `CookieStore` if an error occurs.
 *
 * @returns A memoized default store instance.
 */
const createDefaultStore = memoize((): Store => {
  try {
    return new LocalStorageStore();
  } catch {
    return new CookieStore();
  }
});

export type { Store };
export { createDefaultStore, CookieStore, LocalStorageStore, MemoryStore };
