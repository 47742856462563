/* eslint-disable camelcase */
import type { StoreObject } from '@apollo/client/cache';
import type {
  DegreePlan_Course as DegreePlanCourse,
  DegreePlan_PersonalizedSegmentCourse as PersonalizedSegmentCourse,
} from '__generated__/graphql-types-do-not-use';

import type { QuestionBankSummariesV1 } from 'bundles/naptimejs/resources/__generated__/QuestionBankSummariesV1';
import type { EntityKeyFieldsMap } from 'bundles/page/lib/network/typePolicies';

// Is your GraphQL entity not caching correctly? Read below!
//
// Perhaps Apollo cannot identify your entity because it lacks an `id` field, and therefore it cannot cache it (or it
// incorrectly overwrites it in the cache). You can specify here a list of field names for your entity's type name to
// tell Apollo what your entity's identifier is. These keys will be used to normalize it in the cache. You MUST include
// these fields in your query, otherwise Apollo will throw an error.
//
// Perhaps you have an key conflict, where you have a [__typename, id] combination that is not unique (even though it
// should be). You can specify here a `false` for your entity's type name to turn off cache normalization for your type.
//
// Perhaps you have a namespace object. You can specify here a `[]` for your entity's type name to turn on namespace
// handling.

// Link for further information for apollo cache: https://www.apollographql.com/docs/react/caching/cache-field-behavior

const EntityKeyFields: EntityKeyFieldsMap = {
  GroupGrading_Item: ['itemId'],
  GroupGrading_Group: ['groupId'],
  RestQuizQuestionDataElement: ['id'],
  RestQuizQuestionDataElementResult: false,
  DiscoveryCollections_cursoredProductCollections: false,
  Submission_WidgetQuestion: false,
  Submission_UrlQuestion: false,
  Submission_TextReflectQuestion: false,
  Submission_TextExactMatchQuestion: false,
  Submission_TextBlock: false,
  Submission_RubricOption: false,
  Submission_RichTextQuestion: false,
  Submission_RegexQuestion: false,
  Submission_PlainTextQuestion: false,
  Submission_OffPlatformQuestion: false,
  Submission_NumericQuestion: false,
  Submission_MultipleFillableBlanksQuestion: false,
  Submission_MultipleChoiceReflectQuestion: false,
  Submission_MultipleChoiceQuestion: false,
  Submission_MultipleChoiceOption: false,
  Submission_MultipleChoiceFillableBlankResponse: false,
  Submission_MultipleChoiceFillableBlank: false,
  Submission_MathQuestion: false,
  Submission_GradedWidgetQuestion: false,
  Submission_GradedUrlQuestion: false,
  Submission_GradedTextReflectQuestion: false,
  Submission_GradedTextExactMatchQuestion: false,
  Submission_GradedRichTextQuestion: false,
  Submission_GradedRegexQuestion: false,
  Submission_GradedPlainTextQuestion: false,
  Submission_GradedOption: false,
  Submission_GradedOffPlatformQuestion: false,
  Submission_GradedNumericQuestion: false,
  Submission_GradedMultipleFillableBlanksQuestion: false,
  Submission_GradedMultipleChoiceReflectQuestion: false,
  Submission_GradedMultipleChoiceQuestion: false,
  Submission_GradedMultipleChoiceFillableBlank: false,
  Submission_GradedMathQuestion: false,
  Submission_GradedFileUploadQuestion: false,
  Submission_GradedCodeExpressionQuestion: false,
  Submission_GradedCheckboxReflectQuestion: false,
  Submission_GradedCheckboxQuestion: false,
  Submission_FileUploadQuestion: false,
  Submission_Feedback: false,
  Submission_CodeExpressionQuestion: false,
  Submission_CheckboxReflectQuestion: false,
  Submission_CheckboxQuestion: false,
  AssignmentPresentations_MachineEvaluation: false,
  AssignmentPresentations_OptionsOption: false,
  AssignmentPresentations_PromptSubmissionPrompt: false,
  AssignmentPresentations_PromptReviewPrompt: false,
  AssignmentPresentations_ReviewPartSchemaDetailsOptionsReviewSchema: false,
  // caching is disabled on AuthoringProgrammingAssignmentsV3 since it is reused in a few spots, and bc it doesn' thave
  // a reliable id, we don't want to cache it. See example for how existing data can be dropped on non-normalized object.
  // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
  AuthoringProgrammingAssignmentsV3: false,
  LearnerPlan_PlannedSegmentCourse: false,
  DegreePlan_LearnerProgress: false,
  DegreePlan_SegmentCourse: false,
  ItemPlatform_LectureContent: false,
  DegreePlan_Course: (object: Readonly<StoreObject>) => {
    return `DegreePlan_Course: ${(object as DegreePlanCourse).courseCode}-${(object as DegreePlanCourse).courseName}`;
  },
  LearnerPlan_TermPlan: ['estimatedTermId'],
  LearnerPlan_LearnerPlanResult: [],
  DegreeEvent_AssignmentEvent: false,
  Collection_Collection: false,
  Recommendation_Collection: ['id', 'additionalInput'],
  DegreePlan_PersonalizedSegmentCourse: (object: Readonly<StoreObject>) => {
    return `DegreePlan_PersonalizedSegmentCourse: ${(object as PersonalizedSegmentCourse).segmentCourse.id}`;
  },
  NaptimeQuestionBankSummariesV1: (object: Readonly<StoreObject>) => {
    const questionBankSummariesV1 = object as {} as QuestionBankSummariesV1;
    return `NaptimeQuestionBankSummariesV1:${
      questionBankSummariesV1?.embedSourceCourseId
        ? questionBankSummariesV1?.metadata?.publishedId
        : questionBankSummariesV1?.id
    }`;
  },
};

export default EntityKeyFields;
