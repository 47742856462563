import React from 'react';

import type { IconColor } from './getSvgIconCss';

export type IconProps = {
  /**
   * The size of the icon.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * The color of the icon.
   * @default undefined
   */
  color?: IconColor;

  className?: string;

  /**
   * Provides a human-readable title for the svg.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  title?: string;

  /**
   * Ref that points to the SVG node of the icon
   */
  ref?: React.LegacyRef<SVGSVGElement>;

  /**
   * Longer form accessible description for the icon
   */
  desc?: string;
} & React.SVGProps<SVGSVGElement>;

export const IconContext = React.createContext<IconProps | null>(null);
