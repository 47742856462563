import * as React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  useFollowForDisablingIndexing?: boolean;
};

/** provides meta robots noindex */
const DisableCrawlerIndexing = ({ useFollowForDisablingIndexing }: Props) => {
  const content = useFollowForDisablingIndexing ? 'noindex, follow' : 'noindex, nofollow';
  return <Helmet meta={[{ name: 'robots', content }]} />;
};

export default DisableCrawlerIndexing;
