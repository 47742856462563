import type { Events as AllEvents } from '@coursera/event-pulse/core';

import { low as client } from './client';
import * as memory from './pageMemory';

type KeysWithPage<T> = {
  [K in keyof T]: 'page' | 'user_properties' extends keyof T[K] ? K : never;
}[keyof T];

type Events = Omit<Pick<AllEvents, KeysWithPage<AllEvents>>, 'view_page'>;

type EventData<K extends keyof Events> = Omit<Events[K], 'page' | 'user_properties'>;
type EventOptions<K extends keyof Events> = Events[K]['user_properties'];

/**
 * Track an event. Page information will be automatically added to the event.
 *
 * @param eventName The name of the event. If the event name contains eventingv3. prefix, you don't need to include it.
 * @param data The event data.
 * @param options If the event includes user properties, they can be passed in here.
 */
async function track<K extends keyof Events>(
  eventName: K,
  data: EventData<K>,
  options: EventOptions<K> = {}
): Promise<void> {
  // Transforming trackPageView to a promise, makes memory to be unavailable until the page view is sent.
  // The workaround is to transform this track to a promise as well.

  // This is consumed in a queue, so after a page view is executed (no need to be sent),
  // the memory is populated with a promise that will be resolved when the page view is sent.
  // -- Part of the same line --
  // user_properties is part of the Amplitude SDK, we cannot rename it
  // eslint-disable-next-line camelcase
  const payload = memory.get().then((page) => ({ ...data, page, user_properties: options } as AllEvents[K]));

  return client.sendEvent(eventName, payload);
}

export type { Events, EventData, EventOptions };

export default track;
