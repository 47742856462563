import AdminGoogleTagManager from 'bundles/event-tracking/AdminGoogleTagManager';
import LearnerGoogleTagManager from 'bundles/event-tracking/LearnerGoogleTagManager';
import { shouldLoadAdminGTM, shouldLoadLearnerGTM } from 'bundles/event-tracking/shouldLoadAdTracking';

/**
 * Enable ads tracking where it can be done safely.
 * (e.g. no Google in China, password reset url, internal, superuser, instructor, admin pages)
 * This function should only be loaded after the cookie banner script has been loaded (if you're in the experiment)
 */
export default function safeEnableAdsTracking() {
  if (shouldLoadLearnerGTM()) {
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    const googleTagManager = new LearnerGoogleTagManager();
    googleTagManager.ensureLibLoaded();
  } else if (shouldLoadAdminGTM()) {
    const googleTagManager = new AdminGoogleTagManager();
    googleTagManager.ensureLibLoaded();
  }
}
