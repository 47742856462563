import type { JsAppConfig } from 'js/app/config';
import config from 'js/app/config';

export const GTM_DATA_LAYER = 'dataLayer';
export const GTM_GTAG = 'gtag';

export type EvenTrackingConfig = {
  config: JsAppConfig;
  timeout: number;
  signup: string;
  enroll: string;
  learnerGoogleTagManager: GoogleTagManagerConfig;
  adminGoogleTagManager: GoogleTagManagerConfig;
};

export type GoogleTagManagerConfig = {
  url: string;
  copyContainerUrl?: string;
};

const exported: EvenTrackingConfig = {
  config,
  timeout: 500,
  signup: 'signup',
  enroll: 'enroll',
  learnerGoogleTagManager: {
    url: 'https://www.googletagmanager.com/gtm.js?id=GTM-5JKLVK&l=dataLayer',
    // This is used for Meta Pixel testing only
    copyContainerUrl: 'https://www.googletagmanager.com/gtm.js?id=GTM-5TWWKN76&l=dataLayer',
  } as const,
  adminGoogleTagManager: {
    url: 'https://www.googletagmanager.com/gtm.js?id=GTM-W2JHMKRC&l=dataLayer',
  } as const,
};

export default exported;
export { config };

export const { timeout, signup, enroll, learnerGoogleTagManager, adminGoogleTagManager } = exported;
