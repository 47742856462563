import React from 'react';

import { Link as MuiLink } from '@material-ui/core';

import type {
  IconProps,
  OverrideProps,
  OverridableComponent,
} from '@coursera/cds-common';

import type { TypographyVariant } from '@core/Typography2';

import getLinkCss, {
  classes,
  getAfterIconCss,
  getBeforeIconCss,
} from './getLinkCss';

type BaseProps = {
  /**
   * Render an icon next to the link.
   */
  icon?: React.ReactElement<IconProps>;

  /**
   * Specify positioning of the icon relative to the link.
   * @default after
   */
  iconPosition?: 'before' | 'after';

  /**
   * Render the link standalone (outside of inline text). Adds padding to ensure bigger hit area for the link.
   * @default false
   */
  standalone?: boolean;

  /**
   * Enable distinct styling for `:visited` state of link.
   * @default false
   */
  enableVisitedState?: boolean;

  /**
   * The link variant to use
   * @default standard
   */
  variant?: 'standard' | 'quiet';

  /**
   * The typography variant to use.
   * @default inherit
   */
  typographyVariant?: Extract<
    TypographyVariant,
    | 'subtitleLarge'
    | 'subtitleMedium'
    | 'bodyPrimary'
    | 'bodySecondary'
    | 'inherit'
  >;

  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;

  children: React.ReactNode;
};

export interface LinkTypeMap<D extends React.ElementType = 'a'> {
  props: BaseProps;
  defaultComponent: D;
}

export type Props<
  D extends React.ElementType = LinkTypeMap['defaultComponent']
> = OverrideProps<LinkTypeMap<D>, D> & { component?: React.ElementType };

/**
 * Links are navigational elements that direct users to different pages or further information.
 *
 * See [Props](__storybookUrl__/components-navigation-link--default#props)
 */
const Link: OverridableComponent<LinkTypeMap> = React.forwardRef(function Link(
  props: Props,
  ref: React.Ref<HTMLAnchorElement>
) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    icon,
    iconPosition,
    enableVisitedState,
    standalone,
    variant,
    children,
    typographyVariant,
    invert,
    ...rest
  } = props;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const css = getLinkCss(props);

  return (
    <MuiLink
      ref={ref}
      classes={{ focusVisible: classes.focusVisible }}
      css={css}
      {...rest}
    >
      {icon && iconPosition === 'before' && (
        <span className={classes.icon} css={getBeforeIconCss}>
          {icon}
        </span>
      )}

      {children}

      {icon && iconPosition === 'after' && (
        <span className={classes.icon} css={getAfterIconCss}>
          {icon}
        </span>
      )}
    </MuiLink>
  );
});

Link.defaultProps = {
  variant: 'standard',
  iconPosition: 'after',
  typographyVariant: 'inherit',
};

export default Link;
