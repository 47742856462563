import * as Sentry from '@sentry/react';

import logger from 'js/app/loggerSingleton';

import type { Events } from '@coursera/event-pulse/core';

type Page = Partial<Events['view_page']['page']>;

let memory: Promise<Page | undefined> = Promise.resolve(undefined);
let resolve: (value: Page) => void | undefined;

async function get(): Promise<Page> {
  // Peek the results
  const page = await memory;

  // If the memory is undefined, log an error and capture it with Sentry
  if (page === undefined) {
    // TODO(@adrian): Replace this log with the future @coursera/log
    logger.error('[Eventing]: Page options have not been set');

    const error = new Error('[Eventing]: Page options have not been set');
    Sentry.captureException(error);

    // No page information is available
    return {};
  }

  return page;
}

function reset() {
  memory = new Promise<Page>((ok) => {
    resolve = ok;
  });
}

function set(value: Page): void {
  resolve?.(value);
}

export { get, reset, set };
