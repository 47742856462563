import * as React from 'react';

import { compose } from 'recompose';

import connectToStores from 'js/lib/connectToStores';
import Retracked from 'js/lib/retracked';
import type UserAgentInfo from 'js/lib/useragent';

import UserAgentApiContext from 'bundles/page/contexts/UserAgentApiContext';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import 'css!../styl/index';

type PropsFromCaller = {
  children?: JSX.Element;
};

type PropsFromStore = {
  csrfToken: string;
  requestCountryCode: string;
  userAgent: UserAgentInfo;
};

type PropsToComponent = PropsFromCaller & PropsFromStore;

export class AuthenticationApp extends React.Component<PropsToComponent> {
  render() {
    const { children, userAgent } = this.props;
    return (
      <UserAgentApiContext.Provider value={userAgent}>
        <div className="rc-AuthenticationApp">{children}</div>
      </UserAgentApiContext.Provider>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'authentication',
    },
  })),
  connectToStores<PropsToComponent, PropsFromCaller>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getState().userAgent,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  }))
)(AuthenticationApp);
