// NOTE these functions replace window.location and do not send Location: headers
import Uri from 'jsuri';
import each from 'lodash/each';
import extend from 'lodash/extend';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';

import { redirect as prerenderRedirect } from 'js/lib/prerender';

const encodeParams = function (url, params) {
  const uri = new Uri(url);
  if (!isUndefined(params)) {
    if (!isUndefined(params.r)) {
      params.redirectTo = params.r;
    }
    each(omit(params, 'r'), function (value, key) {
      uri.addQueryParam(key, value);
    });
  }

  return uri.toString();
};

export const getQueryParams = function () {
  return window.location.search;
};

export const getPathname = function () {
  return window.location.pathname;
};

// if the host prefix contains backslash, it can lead to potential open redirection attacks
// for example: https://www.coursera.org/login?r=https://2899908099\dd.coursera.org/ would
// redirect the page to https://172.217.22.3
const isSafeHostPrefix = function (host) {
  return !/\\/g.test(host.split('.')[0]);
};

const getDomainFromHost = function (host) {
  return host.split('.').slice(-2).join('.');
};

export const isFromSameDomain = function (urlA, urlB) {
  const hostA = new Uri(urlA).host();
  const hostB = new Uri(urlB).host();
  return hostA === hostB || (isSafeHostPrefix(hostA) && getDomainFromHost(hostA) === getDomainFromHost(hostB));
};

export const setLocation = function (newUrl, options) {
  const statusCode = options && options.isPermanent ? 301 : 302;
  prerenderRedirect(statusCode, newUrl);

  const parsedUrl = new Uri(newUrl);

  // only newUrl if hosts match or there is no host (relative url)
  let safeUrl = '/';
  if (parsedUrl.host() === '' || isFromSameDomain(newUrl, window.location.host)) {
    safeUrl = parsedUrl.toString();
  }
  window.location.href = safeUrl;
  return safeUrl;
};

export const refresh = function () {
  window.location.reload();
};

export const getAuthRedirect = function (mode, params) {
  const urlParams = extend(
    {
      r: window.location.href,
    },
    params
  );
  const authUrl = '/' + (mode || 'signup');
  return encodeParams(authUrl, urlParams);
};

//
// These functions rewrite history
//
/**
 * Redirect to auth pages and redirect back to the current page after authentication
 */
export const authenticate = function (mode, params) {
  return window.location.replace(getAuthRedirect(mode, params));
};

export default {
  setLocation,
  authenticate,
  getPathname,
  getQueryParams,
  refresh,
  isFromSameDomain,
};
