import Q from 'q';

import Multitracker from 'js/app/multitrackerSingleton';
import Cookie from 'js/lib/cookie';
import memoize from 'js/lib/memoize';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'js/l... Remove this comment to see the full error message
import thirdParties from 'js/lib/thirdParties';
import timing from 'js/lib/timing';

import { GTM_DATA_LAYER, GTM_GTAG } from 'bundles/event-tracking/constants';

export function trackEvent(eventName: string, params?: Record<string, unknown>) {
  const multiTrackerEventKey = 'adsTracker.googleTagManager.' + eventName;
  Multitracker.pushV2([multiTrackerEventKey, params]);
}

export const loadGTMScript = memoize((scriptUrl: string) => {
  const deferred = Q.defer();

  timing.time('googleTagManager');
  window[GTM_DATA_LAYER] = window[GTM_DATA_LAYER] || [];
  window[GTM_DATA_LAYER]?.push({
    'gtm.start': new Date().getTime(),
    sessionId: Cookie.get('__204u') || '1234',
    event: 'gtm.js',
  });
  window[GTM_GTAG] = function (...args) {
    window[GTM_DATA_LAYER]?.push(args);
  };
  thirdParties.loadScript(
    scriptUrl,
    function () {
      timing.timeEnd('googleTagManager');
      deferred.resolve(window[GTM_DATA_LAYER]);
    },
    function () {
      trackEvent('error');
    }
  );

  return deferred.promise;
});
